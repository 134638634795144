import React, { useState } from "react";
import { Editor as TinyMce } from "@tinymce/tinymce-react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import Error from "./Error";

/**
 * prop definition
 */
const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func
};

const defaultProps = {
  init: { menubar: true, toolbar: true },
  setFieldTouched: () => {}
};

const Template = ({
  init,
  name,
  value,
  label,
  setFieldValue,
  setFieldTouched,
  wrapperClassName,
  placeholder = "",
  ...props
}) => {
  /**
   * state
   */
  const [focus, setFocus] = useState(false);

  return (
    <Form.Group controlId={name} className={wrapperClassName || "mb-6"}>
      {label && <Form.Label>{label}</Form.Label>}
      <Textarea $focus={focus}>
        <TinyMce
          value={value || ""}
          plugins={["link image"]}
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          onEditorChange={(content) => {
            return (
              setFieldValue(name, content) |
              setTimeout(() => setFieldTouched(name, true))
            );
          }}
          init={{
            ...init,
            placeholder,
            setup: (editor) => {
              editor.on("focus", () => setFocus(true));
              editor.on("blur", () => setFocus(false));
            },
            content_style: `
            body {
              color: #000;
              font-size: 1rem;
              line-height: 1.2;
              letter-spacing: 0.15px;
              font-family: "EudoxusSans", sans-serif !default;
            }

            .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
              letter-spacing: 0.15px;
              font-size: 1rem;
              color: #abadae;
            }
        `
          }}
          {...props}
        />
      </Textarea>
      <Error {...{ name, withFormik: true }} />
    </Form.Group>
  );
};

Template.propTypes = propTypes;
Template.defaultProps = defaultProps;

const Textarea = styled.div`
  .tox-tinymce {
    border: solid 1px
      ${({ $focus }) => ($focus ? "var(--bs-primary)" : "var(--bs-gray-20)")};
    border-radius: 2px;
    box-shadow: ${({ $focus }) =>
      $focus ? "0px 0px 0px 3px rgba(0, 123, 124, 0.2)" : "none"};
  }

  .tox-toolbar__primary {
    background: none !important;
    border-bottom: solid 1px var(--bs-gray-20);
  }

  .tox-tbtn--enabled,
  .tox-tbtn:hover {
    background: var(--bs-gray-10) !important;
  }
`;

export default Template;
