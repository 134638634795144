import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Index from "../Views/Index.";
import Verify from "./Verify";

const AppRoutes = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/verify" element={<Verify />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AppRoutes;
