import React, { Fragment } from "react";
import { components } from "react-select";
import styled from "styled-components";
import ChevronIcon from "../Icons/Chevron";

// dropdown indicator
const DropdownIndicator = ({ children, isFocused, hasValue, ...props }) => (
  <components.DropdownIndicator {...props}>
    <ChevronIcon {...{ direction: isFocused && !hasValue ? "up" : "down" }} />
  </components.DropdownIndicator>
);

// option view
const Content = ({ data, ...props }) => (
  <div className="d-flex align-items-center">
    {data?.icon && (
      <Fragment>
        {data?.type === "url" && <Icon icon={data.icon} />}
        {data?.type === "svg" && (
          <Icon svg>
            <data.icon width={24} height={24} />
          </Icon>
        )}
      </Fragment>
    )}
    <p className="mb-0 text-truncate">{data?.label}</p>
  </div>
);

const Components = {
  IndicatorSeparator: () => null,
  DropdownIndicator,
  Option: (props) => (
    <components.Option {...props}>
      <Content {...props} />
    </components.Option>
  ),
  SingleValue: (props) => (
    <components.SingleValue {...props}>
      <Content {...props} />
    </components.SingleValue>
  ),
};

/**
 * styles
 */
export const styles = {
  container: (styles) => ({
    ...styles,
    padding: 0,
    width: "100%",
  }),
  input: (styles) => ({
    ...styles,
  }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    borderColor: "transparent !important",
    backgroundColor: isDisabled ? "var(--bs-gray-10)" : "transparent",
    borderRadius: "0.375rem",
    minHeight: "2.875rem",
    boxShadow: "none",
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    overflow: "auto",
    maxHeight: 200,
  }),
  menuPortal: (styles) => ({ ...styles, zIndex: 999 }),
  menu: (styles) => ({
    ...styles,
    boxShadow: "0 0.0625rem 0.375rem 0.0625rem rgb(6 31 60 / 12%)",
    border: "none !important",
    backgroundColor: "#fff",
    paddingBottom: "0.25rem",
    paddingTop: "0.25rem",
    zIndex: 999,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor:
      (isSelected && "var(--bs-gray-10)!important") || "#fff!important",
    padding: "0.625rem 1rem",
    fontSize: "0.875rem",
    color: "#000",
    cursor: "pointer",
    outline: "none",
  }),
  multiValue: (styles) => ({
    gap: 4,
    display: "flex",
    borderRadius: 24,
    alignItems: "center",
    padding: "0.5rem 0.75rem",
    backgroundColor: "var(--bs-primary-10)",
  }),
  multiValueLabel: (styles) => ({ fontSize: "0.875rem" }),
  multiValueRemove: (styles) => ({
    ...styles,
    padding: "0",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#000",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    gap: 4,
    height: "100%",
    padding: "0rem 0.875rem",
  }),
  singleValue: (styles) => ({
    ...styles,
    width: "100%",
    cursor: "pointer",
    color: "#000",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "var(--bs-gray-80)",
    fontWeight: 400,
  }),
  indicatorsContainer: (styles) => ({ ...styles, cursor: "pointer" }),
};

/**
 * styled component styles
 */
const Icon = styled.div`
  ${({ svg, icon }) =>
    !svg &&
    `
        background-color: var(--gray-40);
        background-image: url(${icon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
    `}
  flex: 0 0 1.5rem;
  height: 1.5rem;
`;

const Wrapper = styled.div`
  .Select-menu-outer {
    position: relative;
    z-index: 999;
  }
`;

export default Object.assign(Components, { Wrapper, styles });
