import React from "react";
import Select from "react-select";

import Components from "../Components/Select/Components";

/**
 *
 * @param {*} props
 * @returns
 */
const defaultProps = {
  as: Select,
};

const Template = ({ placeholder, ...props }) => (
  <Components.Wrapper
    components={Components}
    styles={Components.styles}
    placeholder={placeholder || ""}
    {...props}
  />
);

/**
 * functions
 */
const handleValue = ({ isMulti, value, options = [] }) => {
  if (isMulti) {
    return options.filter((option) =>
      (value || []).find((item) => option.value === item)
    );
  }
  return options.find((option) => option.value === value) || "";
};

Template.defaultProps = defaultProps;

export default Object.assign(Template, { handleValue });
