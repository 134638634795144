import React, { useState, Fragment, useCallback } from "react";
import { object, string } from "yup";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Form from "react-bootstrap/Form";

import CheckedBox from "./Icons/Checkbox";
import Close from "./Icons/Close";
import Field from "./Field";

/**
 * props definition
 */
const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  withFormik: PropTypes.bool,
  handleGpsData: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

const defaultProps = {
  handleGpsData: () => {},
  withFormik: true,
  value: "",
};

const GPSVerifier = ({
  name,
  value,
  withFormik,
  setFieldValue,
  setFieldError,
  handleGpsData,
  setFieldTouched,
  ...props
}) => {
  /**
   * states
   */
  const [valid, setValid] = useState(false);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * function
   */

  // email validator
  const schema = object().shape({
    gps: string()
      .test(
        "format",
        "Enter a valid digital address",
        (address) => address && address?.match(/^[A-Z0-9]{2}-\d{3,4}-\d{3,4}$/)
      )
      .max(12, "GPS must be maximum of 12 characters"),
  });

  // verify email with bouncer
  const handleEmailVerification = useCallback(
    debounce((gps) => {
      setLoading(true);
      setTyping(false);

      schema.isValid({ gps }).then((valid) => {
        if (valid) {
          axios
            .get(
              `https://api-prod.nssghprod.com/flair/onboarding/api/companyonboarding/verify_ghanapostgps?gpscode=${gps}`
            )
            .then(({ data: { ghanapostdetails } }) => {
              setValid(true);
              setFieldValue("isGPSValid", true);
              handleGpsData(ghanapostdetails[0]);
            })
            .catch((err) => {
              console.log(err);
              setValid(false);
              setFieldValue("isGPSValid", false);

              if (setFieldError) {
                setFieldError(name, "Unable to verify gps");
              }
            })
            .finally(() => setLoading(false));
        } else {
          setValid(false);
          setLoading(false);
          setFieldValue("isGPSValid", false);
        }
      });
    }, 2000),
    [handleGpsData, name, setFieldError, setFieldValue]
  );

  // form change handler
  const handleChange = (value) => {
    setTyping(true);

    // for formik
    setFieldValue(name, value);
    setFieldValue("isGPSValid", false);

    handleEmailVerification(value);
  };

  return (
    <Field
      name={name}
      value={value || ""}
      component={Form.Control}
      onBlur={() => setFieldTouched(name, true)}
      postfix={<InputStatus {...{ typing, value, valid, loading }} />}
      onChange={({ currentTarget: { value } }) => handleChange(value?.trim())}
      {...{ withFormik, ...props }}
    />
  );
};

const InputStatus = ({ typing, value, valid, loading }) => {
  return (
    <Fragment>
      {value && (
        <div className="d-flex align-items-center justify-content-center px-4">
          {loading && <Spinner size="sm" animation="border" />}
          {!loading && (
            <Fragment>
              {valid ? (
                <CheckedBox
                  checked
                  variant="circle"
                  color="var(--bs-primary)"
                />
              ) : (
                !typing && <Close color="var(--bs-red)" />
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

GPSVerifier.propTypes = propTypes;
GPSVerifier.defaultProps = defaultProps;

export default GPSVerifier;
