import React, { useState, Fragment, useCallback } from "react";
import { object, string } from "yup";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Form from "react-bootstrap/Form";
import CheckedBox from "../Components/Icons/Checkbox";
import Close from "../Components/Icons/Close";
import Field from "./Field";

/**
 * props definition
 */
const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  withFormik: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  setFieldTouched: PropTypes.func,
};

const defaultProps = {
  withFormik: true,
  value: "",
};

const EmailVerifier = ({
  name,
  value,
  withFormik,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  ...props
}) => {
  /**
   * states
   */
  const [valid, setValid] = useState(false);
  const [typing, setTyping] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * function
   */

  // email validator
  const schema = object().shape({
    email: string().email("Enter a valid email").required("Email is required"),
  });

  // verify email with bouncer
  const handleEmailVerification = useCallback(
    debounce((email) => {
      setLoading(true);
      setTyping(false);

      schema.isValid({ email }).then((valid) => {
        if (valid) {
          axios
            .get(`https://api.usebouncer.com/v1/email/verify?email=${email}`, {
              headers: {
                "x-api-key": process.env.REACT_APP_BOUNCER_KEY,
              },
            })
            .then(({ data: { status } }) => {
              if (status !== "undeliverable") {
                setValid(true);
                setFieldValue("isEmailValid", true);
                setFieldError(name, "Unable to verify email");
              } else {
                setValid(false);
                setFieldValue("isEmailValid", false);

                if (setFieldError) {
                  setFieldError(name, "Invalid email address");
                }
              }
            })
            .finally(() => setLoading(false));
        } else {
          setValid(false);
          setLoading(false);
          setFieldValue("isEmailValid", false);
        }
      });
    }, 2000),
    []
  );

  // form change handler
  const handleChange = (value) => {
    setTyping(true);

    // for formik
    setFieldValue(name, value);
    setFieldValue("isEmailValid", false);

    handleEmailVerification(value);
  };

  return (
    <Field
      name={name}
      value={value || ""}
      component={Form.Control}
      onBlur={() => setFieldTouched(name, true)}
      postfix={<EmailState {...{ typing, value, valid, loading }} />}
      onChange={({ currentTarget: { value } }) => handleChange(value)}
      {...{ withFormik, ...props }}
    />
  );
};

const EmailState = ({ typing, value, valid, loading }) => {
  return (
    <Fragment>
      {value && (
        <div className="d-flex align-items-center justify-content-center px-4">
          {loading && <Spinner size="sm" animation="border" />}
          {!loading && (
            <Fragment>
              {valid ? (
                <CheckedBox
                  checked
                  variant="circle"
                  color="var(--bs-primary)"
                />
              ) : (
                !typing && <Close color="var(--bs-red)" />
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
};

EmailVerifier.propTypes = propTypes;
EmailVerifier.defaultProps = defaultProps;

export default EmailVerifier;
