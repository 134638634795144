import React, { useState, useEffect } from "react";
import "../App.css";
// import "flair-ui/dist/index.css";
import "../dist/index.css";
import styled from "styled-components";
import EmailVerifier from "../Components/EmailVerifier";
import Select from "../Components/Select";
import GPSVerifier from "../Components/GPSVerifier";
import {
  requireEmail,
  requireGPS,
  requirePhoneNumber,
  requireString,
} from "../schema";
import Field from "../Components/Field";
import Button from "../Components/Button";
import Link from "../Components/Link";
import { Formik } from "formik";
import { object } from "yup";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const [regionsList, setRegionsList] = useState([]);
  const navigate = useNavigate();
  const roles = [
    {
      label: "C-level:CEO / COO / CIO / CFO / CTO / CPO",
      value: "C-level:CEO / COO / CIO / CFO / CTO / CPO",
    },
    {
      label: "Senior Management: Head of Department / Team Lead",
      value: "Senior Management: Head of Department / Team Lead",
    },
    {
      label: "Middle Management: Head of Department / Team Lead",
      value: "Middle Management: Head of Department / Team Lead",
    },
    {
      label: "Junior Level: Associate / Officer",
      value: "Junior Level: Associate / Officer",
    },
  ];

  useEffect(() => {
    axios
      .get(
        "https://api-prod.nssghprod.com/flair/onboarding/api/companyonboarding/list_regions"
      )
      .then((res) => {
        const list = res.data.regionlist;
        setRegionsList(list);
      })
      .catch((err) => console.log(err));
  }, []);

  const regions =
    regionsList?.map((region) => ({
      label: region.regionname,
      value: region.regionname,
      region,
    })) || [];

  return (
    <Container>
      <div>
        <img src="nsslogo.jpeg" alt="nsslogo" id="nsslogo" />
        <h2>Ghana National Service Scheme</h2>
        <h2 style={{ color: "#00A34D" }}>
          LAUNCH OF THE 50TH ANNIVERSARY OF THE NATIONAL SERVICE SCHEME
        </h2>
        <p>Register to participate</p>
        <hr className="mb-8"></hr>
      </div>
      <div>
        <Formik
          validateOnMount
          enableReinitialize
          validationSchema={object({
            fullname: requireString("Full name"),
            work_email: requireEmail("Work email"),
            work_phone: requirePhoneNumber("Work Phone"),
            company: requireString("Company"),
            role: requireString("Role"),
            // gps_address: requireGPS("GPS address"),
            address: requireString("Address"),
            city: requireString("City"),
            region: requireString("Region"),
          })}
          initialValues={{
            fullname: "",
            work_email: "",
            work_phone: "",
            companyid: "1",
            company: "",
            role: "",
            gps_address: "",
            address: "",
            city: "",
            region: "",
          }}
          onSubmit={(
            { isEmailValid, isGPSValid, ...params },
            { setSubmitting, setErrors, resetForm }
          ) => {
            axios({
              method: "POST",
              url: "https://api-prod.nssghprod.com/flair/onboarding/api/conference/register",
              data: params,
            })
              .then((res) => {
                console.log(res);
                navigate("/verify");
                // alert("Registration done successfully");
              })
              .catch((err) => {
                const errorMessage = err.response.data.message;
                if (
                  errorMessage ==
                  "Registration failed, user already registered."
                ) {
                  alert("Email already used for registration");
                }
                console.log(errorMessage);
                setErrors(err);
              })
              .finally(() => setSubmitting(false));

            // resetForm();
          }}
        >
          {({
            isValid,
            isSubmitting,
            handleSubmit,
            setFieldError,
            setFieldValue,
            setFieldTouched,
            values: {
              fullname,
              work_email,
              work_phone,
              companyid,
              company,
              role,
              gps_address,
              region,
              address,
              city,
            },
          }) => (
            <Form className="mb-8">
              <h5 className="mb-4">Personal Information</h5>
              <Field name="fullname" label="Full name" value={fullname || ""} />

              <Div>
                <div className="w-100">
                  <EmailVerifier
                    name="work_email"
                    value={work_email || ""}
                    label="Work email"
                    {...{ setFieldValue, setFieldTouched, setFieldError }}
                  />
                </div>
                <div className="w-100">
                  <Field.Phone
                    name="work_phone"
                    defaultCountry="GH"
                    label="Phone number"
                    value={work_phone || ""}
                    {...{ setFieldValue, setFieldTouched }}
                  />
                </div>
              </Div>

              <div className="mb-10">
                <h5 className="mb-4">Company Information</h5>
                <Field
                  name="company"
                  label="Name of company"
                  value={company || ""}
                />
                <Field
                  name="role"
                  label="Roles"
                  options={roles}
                  component={Select}
                  wrapperClassName="mb-0"
                  placeholder="Select one"
                  onChange={({ value }) => setFieldValue("role", value)}
                  value={Select.handleValue({
                    value: role,
                    options: roles,
                  })}
                />
              </div>
              <div style={{ marginBottom: 40 }}>
                <h5 className="mb-4">Company Location</h5>
                <div style={{ marginBottom: 20 }}>
                  <GPSVerifier
                    maxLength={12}
                    name="gps_address"
                    wrapperClassName="mb-2"
                    value={gps_address || ""}
                    label="Ghana Post GPS Address (Optional)"
                    handleGpsData={({
                      Region: gpsRegion,
                      District,
                      Street,
                      Area,
                    }) => {
                      const region = regions.find(
                        (region) =>
                          region.label.toLowerCase() === gpsRegion.toLowerCase()
                      );

                      if (region) {
                        setFieldValue("region", region?.region);
                        setFieldValue("region", region?.value);
                      }

                      setFieldValue("address", `${Street}, ${District}`);
                      setFieldValue("city", Area);
                    }}
                    {...{ setFieldValue, setFieldError, setFieldTouched }}
                  />

                  <div style={{ backgroundColor: "lightgrey" }}>
                    <small>
                      If you dont know your digital address, get it{" "}
                      <Link
                        onClick={() =>
                          window.open("https://www.ghanapostgps.com/map/")
                        }
                      >
                        HERE
                      </Link>
                    </small>
                  </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                  <Field
                    name="address"
                    label="Address"
                    value={address || ""}
                    wrapperClassName="mb-0"
                  />
                </div>

                <Div>
                  <div className="w-100">
                    <Field
                      name="city"
                      value={city || ""}
                      label="City or Town"
                      wrapperClassName="mb-0"
                    />
                  </div>
                  <div className="w-100">
                    <Field
                      label="Region"
                      name="region"
                      options={regions}
                      component={Select}
                      wrapperClassName="mb-0"
                      placeholder="Select region"
                      onChange={({ value }) => setFieldValue("region", value)}
                      value={Select.handleValue({
                        value: region,
                        options: regions,
                      })}
                    />
                  </div>
                </Div>
              </div>
              <div className="w-100">
                <Button
                  variant="custom"
                  border="var(--bs-gray-20)"
                  bg="#00A34D"
                  color="white"
                  type="submit"
                  className="w-100"
                  onClick={handleSubmit}
                  {...{ isValid, isSubmitting }}
                >
                  Register
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 48px;
  margin: auto;

  @media only screen and (min-width: 768px) {
    width: 640px;
  }

  #nsslogo {
    height: 56px;
    margin-bottom: 16px;
  }

  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 16px;
  }

  p {
    color: #6a6a6a;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 20px;
  }
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export default Index;
