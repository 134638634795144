import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../Components/Button";

const Verify = () => {
  return (
    <Container>
      <div>
        <img src="nsslogo.jpeg" alt="nsslogo" id="nsslogo" height="56px" />
        <h2>Ghana National Service Scheme</h2>
        <h2 style={{ color: "#00A34D", marginBottom: 56 }}>
          LAUNCH OF THE 50TH ANNIVERSARY OF THE NATIONAL SERVICE SCHEME
        </h2>

        <hr className="mb-8"></hr>
      </div>
      <h3 style={{ marginBottom: 48 }}>Registration done successfully</h3>
      <Link to="/">
        <Button>Register Another Participant</Button>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
`;

export default Verify;
